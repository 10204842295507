<template>
    <main id="liste">
        <HeaderTab 
            :title="$t('menu.setting.notif_note_management')"
        />

        <div id="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="box">
                            <CustomTable
								ref="table"
                                id_table="note_notify_ca"
                                primaryKey="note_id"
								:items="notes"
								:table_busy.sync="table_busy"
								:hrefsRoutes="config_table_hrefs"
								:externSlotColumns="['note_synthetize', 'all_tiers']"
								:rawColumns="['note_comment']"
                            >
								<template v-slot:[`custom-slot-cell(note_synthetize)`]="{ data }">
									<div 
										v-if="data.note_synthetize && Object.keys(data.note_synthetize).length > 0" 
										class="previewColIdentifier"
									>
										<span 
											v-for="(font, key) in data.note_synthetize.type_fonts"
											:key="key"
										>
											<font-awesome-icon :icon="font.font" /> {{ font.occurences }}
										</span>
									</div>
								</template>

								<template v-slot:[`custom-slot-cell(all_tiers)`]="{ data }">
									<span v-if="data.tiers">
										<span v-for="tier in data.tiers" :key="tier.tiers_id">
											{{ tier.tiers_rs }}<br>
										</span>
									</span>
								</template>
							</CustomTable>
                        </div>
                    </div>
                </div>
            </div>
        </div>

		<ModelChooser
			ref="modeles_modal"
			@submit="notifySubmit"
		/>
    </main>
</template>


<script type="text/javascript">
    import NotesMixin from "@/mixins/Notes.js";
    import Navigation from "@/mixins/Navigation.js";

	export default {
		name: "acteListeNotification",
		mixins: [Navigation, NotesMixin],
		data () {
			return {
				notes: [],
				table_busy: false,

                events_tab: {
                    'TableAction::notifyNote': this.notifySubmit
				},
				config_table_hrefs: {
					'note_title': {
                        routeUniqueName: 'notePreview',
                        routeName: 'notesManagement',
                        params: {
                            note_id: 'note_id'
                        }
                    },
                    'horse.horse_nom': {
                        routeUniqueName: 'horseFiche',
                        routeName: 'horseFiche',
                        params: {
                            horse_id: 'horse.horse_id'
                        }
                    }
                },
			}
		},
		created() {
            this.init_component()
		},
		methods: {
            async init_component() {
                this.table_busy = true
				this.notes = await this.getNoteToNotify()
                this.table_busy = false
			},
			async notifySubmit(note_ids) {
				await this.notifyClientNotes(note_ids, 0)
                .then(() => {
                    this.successToast('toast.notif_sent')
                })
				.catch(() => {
					this.failureToast("toast.info_save_failed")
				})
			}
		},
		components: {
            HeaderTab: () => import('@/components/HeaderTab'),
			CustomTable: () => import('GroomyRoot/components/Table/CustomTable'),
            ModelChooser: () => import('@/components/Model/ModelChooser')
		}
	}
</script>